import * as Types from '../../../../../__generated__/graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OrderBoxSliderQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type OrderBoxSliderQuery = { readonly __typename?: 'query_root', readonly pipe_proposals_aggregate: { readonly __typename?: 'pipe_proposals_aggregate', readonly aggregate: { readonly __typename?: 'pipe_proposals_aggregate_fields', readonly sum: { readonly __typename?: 'pipe_proposals_sum_fields', readonly payout: number | null } | null } | null } };


export const OrderBoxSliderQueryDocument = gql`
    query OrderBoxSliderQuery {
  pipe_proposals_aggregate {
    aggregate {
      sum {
        payout
      }
    }
  }
}
    `;

/**
 * __useOrderBoxSliderQuery__
 *
 * To run a query within a React component, call `useOrderBoxSliderQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderBoxSliderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderBoxSliderQuery({
 *   variables: {
 *   },
 * });
 */
export function useOrderBoxSliderQuery(baseOptions?: Apollo.QueryHookOptions<OrderBoxSliderQuery, OrderBoxSliderQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrderBoxSliderQuery, OrderBoxSliderQueryVariables>(OrderBoxSliderQueryDocument, options);
      }
export function useOrderBoxSliderQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrderBoxSliderQuery, OrderBoxSliderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrderBoxSliderQuery, OrderBoxSliderQueryVariables>(OrderBoxSliderQueryDocument, options);
        }
export type OrderBoxSliderQueryHookResult = ReturnType<typeof useOrderBoxSliderQuery>;
export type OrderBoxSliderQueryLazyQueryHookResult = ReturnType<typeof useOrderBoxSliderQueryLazyQuery>;
export type OrderBoxSliderQueryQueryResult = Apollo.QueryResult<OrderBoxSliderQuery, OrderBoxSliderQueryVariables>;