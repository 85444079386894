/* eslint-disable react/style-prop-object */
import React from "react";

export const CursorSelect: React.FC = () => (
  <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.5 23.125C12.5 23.3779 12.6523 23.6055 12.8857 23.7022C12.9629 23.7344 13.044 23.75 13.125 23.75C13.2871 23.75 13.4473 23.6865 13.5674 23.5674L17.1339 19.9999H21.8751C22.1281 19.9999 22.3556 19.8476 22.4523 19.6142C22.549 19.3808 22.4953 19.1122 22.3165 18.9335L13.5665 10.1835C13.3878 10.0048 13.1183 9.95106 12.8859 10.0477C12.6525 10.1444 12.5001 10.372 12.5001 10.6249L12.5 23.125ZM13.75 12.1338L20.3662 18.75H16.875C16.709 18.75 16.5498 18.8154 16.4326 18.9326L13.7501 21.6161L13.75 12.1338Z"
      fill="#CCCCCC"
      fillOpacity="0.99"
    />
    <path
      d="M11.25 10.625C11.25 11.458 10 11.458 10 10.625C10 9.79199 11.25 9.79199 11.25 10.625Z"
      fill="#CCCCCC"
      fillOpacity="0.99"
    />
    <path
      d="M8.75 10.625C8.75 11.458 7.5 11.458 7.5 10.625C7.5 9.79199 8.75 9.79199 8.75 10.625Z"
      fill="#CCCCCC"
      fillOpacity="0.99"
    />
    <path
      d="M3.75 3.12499C3.75 3.95799 2.5 3.95799 2.5 3.12499C2.5 2.29199 3.75 2.29199 3.75 3.12499Z"
      fill="#CCCCCC"
      fillOpacity="0.99"
    />
    <path
      d="M6.25 3.12499C6.25 3.95799 5 3.95799 5 3.12499C5 2.29199 6.25 2.29199 6.25 3.12499Z"
      fill="#CCCCCC"
      fillOpacity="0.99"
    />
    <path
      d="M8.75 3.12499C8.75 3.95799 7.5 3.95799 7.5 3.12499C7.5 2.29199 8.75 2.29199 8.75 3.12499Z"
      fill="#CCCCCC"
      fillOpacity="0.99"
    />
    <path
      d="M11.25 3.12499C11.25 3.95799 10 3.95799 10 3.12499C10 2.29199 11.25 2.29199 11.25 3.12499Z"
      fill="#CCCCCC"
      fillOpacity="0.99"
    />
    <path
      d="M13.75 3.12499C13.75 3.95799 12.5 3.95799 12.5 3.12499C12.5 2.29199 13.75 2.29199 13.75 3.12499Z"
      fill="#CCCCCC"
      fillOpacity="0.99"
    />
    <path
      d="M13.75 5.62499C13.75 6.458 12.5 6.458 12.5 5.62499C12.5 4.79199 13.75 4.79199 13.75 5.62499Z"
      fill="#CCCCCC"
      fillOpacity="0.99"
    />
    <path
      d="M13.75 8.12499C13.75 8.95799 12.5 8.95799 12.5 8.12499C12.5 7.29199 13.75 7.29199 13.75 8.12499Z"
      fill="#CCCCCC"
      fillOpacity="0.99"
    />
    <path
      d="M3.75 5.62499C3.75 6.458 2.5 6.458 2.5 5.62499C2.5 4.79199 3.75 4.79199 3.75 5.62499Z"
      fill="#CCCCCC"
      fillOpacity="0.99"
    />
    <path
      d="M3.75 8.12499C3.75 8.95799 2.5 8.95799 2.5 8.12499C2.5 7.29199 3.75 7.29199 3.75 8.12499Z"
      fill="#CCCCCC"
      fillOpacity="0.99"
    />
    <path
      d="M3.75 10.625C3.75 11.458 2.5 11.458 2.5 10.625C2.5 9.79199 3.75 9.79199 3.75 10.625Z"
      fill="#CCCCCC"
      fillOpacity="0.99"
    />
    <path
      d="M6.25 10.625C6.25 11.458 5 11.458 5 10.625C5 9.79199 6.25 9.79199 6.25 10.625Z"
      fill="#CCCCCC"
      fillOpacity="0.99"
    />
  </svg>
);
