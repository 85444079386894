import { useTheme } from "@emotion/react";
import React from "react";

import { Text } from "~src/designSystem/atoms/Text";
import { Flexbox } from "~src/designSystem/layout/Flexbox";
import { ICountryCode } from "~src/shared/types";

import { ContactSupport } from "./ContactSupport";

type IProps = {
  country?: ICountryCode;
};

export const BeingReviewed: React.FC<IProps> = ({ country }) => {
  const theme = useTheme();
  return (
    <Flexbox gap="24px" width="400px" marginTop="64px">
      <Text as="h1" size="600">
        Your business is being reviewed
      </Text>
      <Text
        as="p"
        css={{
          color: theme.components.ConnectYourData.descriptionTextColor,
        }}
      >
        Our review process normally takes 24 hours. Once complete, a member of our team will be in
        touch to review your terms with you and answer any questions.
      </Text>
      <ContactSupport country={country} />
    </Flexbox>
  );
};
