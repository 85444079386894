import { useEffect, useMemo, useState } from "react";

import { useVendor } from "~src/shared/auth/useVendor";
import { useVendorDataSources } from "~src/shared/dataSources/useVendorDataSources";
import { useFetchLegalEntity } from "~src/vendor/settingsLegalEntities/useLegalEntity";

export const useShowLegalEntityForm = (): {
  showForm: boolean;
  connections: number;
} => {
  const [loading, setLoading] = useState(true);
  const [connections, setConnections] = useState(0);
  const vendor = useVendor();

  const [showLegalEntity, setShowLegalEntity] = useState(false);
  const legalEntityData = useFetchLegalEntity();

  const { data } = useVendorDataSources(vendor.publicID);

  useEffect(() => {
    // Dont show if vendor already filled in legal entity
    if (
      legalEntityData.loading ||
      legalEntityData.requirement === undefined ||
      !legalEntityData.requirement.required ||
      (legalEntityData.requirement.entity && legalEntityData.requirement.entity.name !== "")
    ) {
      setShowLegalEntity(false);
      return;
    }
    setLoading(false);

    // Show legal entity if a person has at least 1 data source connected.
    if (data.dataSources.length > 0) {
      setConnections(data.dataSources.length);
      setShowLegalEntity(true);
    }
  }, [legalEntityData, data]);

  const showForm = useMemo(() => {
    return !loading && showLegalEntity;
  }, [loading, showLegalEntity]);

  return { showForm, connections };
};
