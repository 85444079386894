import React from "react";

import {
  IBankAccount,
  isValidBankAccountOption,
} from "~/src/shared/bankAccounts/createBankAccountOptions";
import { useAuthContext } from "~src/shared/auth/AuthProvider";
import { useVendor } from "~src/shared/auth/useVendor";

import {
  PayoutAccountsVendorQuery,
  usePayoutAccountsVendorQuery,
} from "./__generated__/PayoutAccounts";

type IVendor = PayoutAccountsVendorQuery["vendors"][number];

export type IPayoutAccount = IBankAccount;

interface IPayoutAccountsContext {
  vendor?: IVendor;
  refetchVendor: () => Promise<void>;
  accounts?: IPayoutAccount[];
  payoutTo?: IPayoutAccount;
  setPayoutMethod: (arg0: string) => void;
}

export const PayoutAccountsContext = React.createContext<IPayoutAccountsContext>({
  vendor: undefined,
  refetchVendor: async () => undefined,
  accounts: [],
  payoutTo: undefined,
  setPayoutMethod: () => undefined,
});

interface IProps {
  children?: React.ReactNode;
}

export const PayoutAccountsProvider: React.FC<IProps> = ({ children }) => {
  const [vendor, setVendor] = React.useState<IVendor | undefined>(undefined);
  const [payoutMethod, setPayoutMethod] = React.useState<string | undefined>(undefined);

  const loggedInVendor = useVendor();

  const { revalidate } = useAuthContext();

  const { data, refetch } = usePayoutAccountsVendorQuery({
    variables: {
      vendorID: loggedInVendor.publicID,
    },
  });

  React.useEffect(() => {
    setVendor(data?.vendors[0]);
  }, [data, refetch]);

  // Compile a list of bank accounts that can potentially be a payout method.
  const accounts = React.useMemo(
    () => data?.vendors[0]?.accounts.filter(isValidBankAccountOption),
    [data],
  );

  const payoutTo = React.useMemo(
    () => accounts?.find((a) => a.public_id === payoutMethod),
    [accounts, payoutMethod],
  );

  const refetchVendor = async () => {
    await revalidate();
    await refetch({ vendorID: loggedInVendor.publicID });
  };

  // This effect ensures that the current payout method is valid. Whenever the
  // accounts list or payout method updates, ensure that it is valid (or set
  // it to the default, which is the checking account with the highest balance).
  React.useEffect(() => {
    // If there are no potential payout methods, then just set it to undefined.
    if (accounts?.length === 0) {
      if (payoutMethod !== undefined) {
        setPayoutMethod(undefined);
      }
      return;
    }

    // Check to see if the current payout method is valid. If it is, then continue.
    if (payoutMethod !== undefined) {
      const acc = accounts?.find((a) => a.public_id === payoutMethod);
      if (acc !== undefined) {
        return;
      }
    }

    // Set the checking account with the highest balance as our payout method.
    const acc = accounts?.reduce((account, x) => {
      if (x.subtype === "checking" && x.balance_current > account.balance_current) {
        return x;
      }
      return account;
    });
    if (acc !== undefined) {
      // Also update the default payout method on the backend.
      setPayoutMethod(acc.public_id);
    }
  }, [accounts, payoutMethod, vendor, data]);

  const value = { vendor: data?.vendors[0], refetchVendor, accounts, payoutTo, setPayoutMethod };

  return <PayoutAccountsContext.Provider value={value}>{children}</PayoutAccountsContext.Provider>;
};

graphql`
  query PayoutAccountsVendorQuery($vendorID: String!) {
    vendors(where: { public_id: { _eq: $vendorID } }) {
      public_id
      rate_months_1
      max_contract_term_months
      payout_method {
        public_id
      }
      accounts {
        ...createBankAccountOptions_account
      }
    }
  }
`;
