import { NextPage } from "next";
import React from "react";

import { VendorOnlyAuth } from "~src/shared/pageWrappers/VendorOnlyAuth";
import { themeDark } from "~src/shared/theme/darkVariant";
import { Inbox } from "~src/vendor/inbox/page";
import { VendorLayout } from "~src/vendor/layout";

const InboxPage: NextPage = () => {
  return (
    <VendorLayout>
      <Inbox />
    </VendorLayout>
  );
};

export default VendorOnlyAuth(InboxPage, { theme: themeDark });
