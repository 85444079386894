import type { IListRowData } from "~src/shared/lists/types";
import type { IListModel } from "~src/shared/lists/types/models";

export const getAvailableTermsFromMaxTerm = (maxTerm: number): number[] => {
  const availableTerms: Array<number> = [];
  const TERM_INCREMENTS_IN_MONTHS = 3;
  // Generate all terms up to (but not including) max term
  for (
    let curTerm = TERM_INCREMENTS_IN_MONTHS;
    curTerm < maxTerm;
    curTerm += TERM_INCREMENTS_IN_MONTHS
  ) {
    availableTerms.push(curTerm);
  }
  // Finally add the max term
  availableTerms.push(maxTerm);
  return availableTerms;
};

export const computeBalanceFromBase = (termLength: number, baseBalance: number): number => {
  const balance = termLength * baseBalance;
  return balance;
};

export const computePayoutFromBase = (
  termLength: number,
  baseBalance: number,
  baseRateBps: number,
): number => {
  const balance = computeBalanceFromBase(termLength, baseBalance);
  const discountRateBps = termLength * baseRateBps;
  const payout = Math.floor(((10000 - discountRateBps) * balance) / 10000);
  return payout;
};

export const calculateBalanceFromTradeableContract = (
  contract: IListRowData<IListModel.lists_tradeable_contracts>,
  termLengthPreferred: number | undefined,
): number => {
  if (contract.max_tradeable_term_length === undefined) {
    throw new Error("Contract is missing max_tradeable_term_length");
  }
  if (contract.base_balance === undefined) {
    throw new Error("Contract is missing base_balance");
  }
  if (contract.base_rate_bps === undefined) {
    throw new Error("Contract is missing base_rate_bps");
  }
  const termToUse = termLengthPreferred ?? contract.max_tradeable_term_length;
  const termLength = Math.min(termToUse, contract.max_tradeable_term_length);
  const balance = computeBalanceFromBase(termLength, contract.base_balance);
  return balance;
};

export const calculatePayoutFromTradeableContract = (
  contract: IListRowData<IListModel.lists_tradeable_contracts>,
  termLengthPreferred: number | undefined,
): number => {
  if (contract.max_tradeable_term_length === undefined) {
    throw new Error("Contract is missing max_tradeable_term_length");
  }
  if (contract.base_balance === undefined) {
    throw new Error("Contract is missing base_balance");
  }
  if (contract.base_rate_bps === undefined) {
    throw new Error("Contract is missing base_rate_bps");
  }
  const termToUse = termLengthPreferred ?? contract.max_tradeable_term_length;
  const termLength = Math.min(termToUse, contract.max_tradeable_term_length);
  const payout = computePayoutFromBase(termLength, contract.base_balance, contract.base_rate_bps);
  return payout;
};
