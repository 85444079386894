/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import React from "react";

import { Flexbox } from "~src/designSystem/layout/Flexbox";
import { breakpoints } from "~src/shared/styles/breakpoints";

import { IInboxStep, useInboxState } from "../state";
import { InboxReviewTable } from "./InboxReviewTable";
import { InboxSelectTable } from "./InboxSelectTable";
import { OrderBox } from "./OrderBox";

export const InboxSelectionArea: React.FC = () => {
  const wizardStep = useInboxState((state) => state.wizardStep);

  return (
    <>
      <Flexbox
        gridColumn="span 2"
        css={css`
          position: relative;
          overflow-x: scroll !important;
        `}
      >
        <Flexbox
          fullHeight
          css={css`
            min-width: fit-content;
          `}
        >
          <SubscriptionsTableWrapper className="inbox-table">
            {wizardStep === IInboxStep.INBOX && <InboxSelectTable />}
            {wizardStep >= IInboxStep.REVIEW && <InboxReviewTable />}
          </SubscriptionsTableWrapper>
        </Flexbox>
      </Flexbox>
      <Flexbox
        gridColumn="span 1"
        css={css`
          ${breakpoints.desktop} {
            margin-top: 0px;
          }
          margin-top: calc(-41px - 24px);
        `}
      >
        <OrderBox />
      </Flexbox>
    </>
  );
};

const SubscriptionsTableWrapper = styled(Flexbox)`
  height: 100%;
  flex-grow: 2;
  position: relative;
`;
