/** @jsxImportSource @emotion/react */
import { css, useTheme } from "@emotion/react";
import { sum } from "lodash";
import React, { useCallback, useMemo } from "react";

import { OldHorizontalRuleDontUse } from "~src/designSystem/deprecated/HorizontalRule";
import { Flexbox } from "~src/designSystem/layout/Flexbox";
import { BackButton } from "~src/designSystem/molecules/BackButton";
import { Radio, RadioGroup } from "~src/designSystem/molecules/RadioGroup";
import {
  RadioDefaultRow,
  RadioGroupDefaultHeader,
} from "~src/designSystem/molecules/RadioGroup/layout";
import { abbrevMonthsList } from "~src/shared/constants";
import { calculateBidPrice } from "~src/shared/helpers";
import { formatBidPrice, formatCents } from "~src/shared/helpers/format";
import { useNotNullContext } from "~src/shared/helpers/useNotNullContext";
import { ISegmentTrackEvent, useAnalytics } from "~src/shared/thirdParties/segment";
import { useSupportedCurrency } from "~src/vendor/utils/useSupportedCurrencies";

import { ProposalsContext } from "../../contexts/ProposalsContext";
import { IInboxStep, useInboxState } from "../../state";
import { calculatePayoutFromTradeableContract } from "../../utils/adjustableTerms";
import { AgreementCheckBox } from "./AgreementCheckBox";
import { PayoutAccountsContext } from "./PayoutMethod/PayoutAccounts";
import { SubmitPayout } from "./SubmitPayout";

interface TermRadioData {
  id: string;
  term: string;
  bidPrice: string;
  amount: string;
}

interface AdjustableTermsScreenProps {
  setIsSelectingTermLength: (arg0: boolean) => void;
}

export const AdjustableTermsScreen: React.FC<AdjustableTermsScreenProps> = (props) => {
  // Track the event in segment
  const { trackEvent } = useAnalytics();
  const theme = useTheme();

  const { vendor } = React.useContext(PayoutAccountsContext);
  const currency = useSupportedCurrency();

  const {
    selectedProposals,
    setTermLength,
    termLength: activeTermLength,
    availableTerms,
  } = useNotNullContext(ProposalsContext);
  const wizardStep = useInboxState((state) => state.wizardStep);

  // We want at least 12 bars, but if the term length is longer, we should increase the
  // number of bars.
  // We also add +1 since no payment is collected during the first month.
  const numSchedules =
    Math.max(...selectedProposals.map((p) => p.max_tradeable_term_length ?? 0), 12) + 1;

  /**
   * List of every month for one year, starting "today"
   */
  const months: string[] = [];
  const today = new Date();

  let monthIndex = today.getMonth();

  for (let i = 0; i < numSchedules; i += 1) {
    months.push(abbrevMonthsList[monthIndex % 12] as string);
    monthIndex += 1;
  }

  const noProposalsSelected = selectedProposals.length === 0;

  const terms: TermRadioData[] = useMemo(() => {
    return availableTerms.map((term) => {
      const selectedProposalsValueCents = sum(
        selectedProposals.map((curr) => calculatePayoutFromTradeableContract(curr, term)),
      );

      const payoutAmountFormatted = formatCents(selectedProposalsValueCents, currency);

      const bidPrice = calculateBidPrice(vendor?.rate_months_1, term);
      const bidPriceformatted = formatBidPrice(bidPrice, currency);

      return {
        id: term.toString(),
        term: `${term} months`,
        bidPrice: bidPriceformatted,
        amount: noProposalsSelected ? "" : payoutAmountFormatted,
      };
    });
  }, [availableTerms, noProposalsSelected, selectedProposals, vendor?.rate_months_1, currency]);

  const handleBackButtonClick = useCallback(() => {
    trackEvent(ISegmentTrackEvent.TradingOrderBoxViewAdjustableTermsBackArrowClick);
    props.setIsSelectingTermLength(false);
  }, [trackEvent, props]);

  const handleRadioInputChange = useCallback(
    (val: string) => {
      const newTermLength = parseInt(val, 10);
      trackEvent(ISegmentTrackEvent.TradingOrderBoxChangeAdjustableTermsRadioClick, {
        newTermLength,
      });
      setTermLength(newTermLength);
    },
    [setTermLength, trackEvent],
  );

  return (
    <Flexbox paddingBottom="20px" paddingTop="8px">
      <BackButton
        css={css`
          padding-top: ${theme.spacing.sm};
        `}
        onClick={handleBackButtonClick}
      >
        Back to Summary
      </BackButton>
      <>
        <RadioGroup
          onChange={handleRadioInputChange}
          value={activeTermLength.toString()}
          headerComponent={
            <RadioGroupDefaultHeader
              leftHeaderLabel="Term Length"
              rightHeaderLabel="Payout Amount"
            />
          }
        >
          {terms.map((term) => (
            <Radio key={term.id} value={term.id}>
              <RadioDefaultRow
                primaryText={term.term}
                secondaryText={`Bid Price ${term.bidPrice}`}
                valueText={term.amount}
              />
            </Radio>
          ))}
        </RadioGroup>
      </>
      <OldHorizontalRuleDontUse color="box" marginTop="12px" marginX="32px" />

      {wizardStep === IInboxStep.REVIEW && (
        <Flexbox paddingX="32px">
          <AgreementCheckBox />
        </Flexbox>
      )}
      <SubmitPayout onReviewOrderClick={() => props.setIsSelectingTermLength(false)} />
    </Flexbox>
  );
};
