import { css, useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import React, { useMemo } from "react";

import { Button } from "~src/designSystem/atoms/Button";
import { Card } from "~src/designSystem/atoms/Card";
import { Flexbox } from "~src/designSystem/layout/Flexbox";
import { useUser } from "~src/shared/auth/useUser";
import { useVendorDataSources } from "~src/shared/dataSources/useVendorDataSources";
import { IOptimizelyExperiments, useOptimizelyDecision } from "~src/shared/thirdParties/optimizely";
import { useLegalEntityFormNewVendor } from "~src/vendor/legalEntities/hooks/useLegalEntityFormNewVendor";
import { useFetchLegalEntity } from "~src/vendor/settingsLegalEntities/useLegalEntity";

import { CircleCheck } from "./icons/CircleCheck";
import { CirclePlus } from "./icons/CirclePlus";

export const LegalEntityCard: React.FC = () => {
  const theme = useTheme();

  const [optimizelyDecision] = useOptimizelyDecision(IOptimizelyExperiments.LegalEntityForm);

  const { vendor } = useUser();
  const { loading, requirement, refetch } = useFetchLegalEntity();
  const { data } = useVendorDataSources(vendor.id);

  const openLegalEntityForm = useLegalEntityFormNewVendor({ onSuccess: refetch });

  const hasLegalEntityRequirement = useMemo(
    () => !loading && requirement && requirement.entity == null,
    [loading, requirement],
  );

  /**
   * Do not render KYB Card if a vendor:
   *   - is not required to (e.g., UK vnedors)
   *   - does not have one or more data sources connected
   *   - the LegalEntityForm experiment is not enabled for the current vendor
   */
  if (
    loading ||
    !requirement ||
    !requirement.required ||
    data.dataSources.length === 0 ||
    !optimizelyDecision.enabled
  ) {
    return null;
  }

  const statusIcon = hasLegalEntityRequirement === true ? <CircleCheck /> : <CirclePlus />;

  return (
    <Card
      isActive={hasLegalEntityRequirement}
      justifyContent="space-between"
      row
      maxWidth="448px"
      flexWrap="wrap"
      padding={theme.spacing.md}
    >
      <Flexbox row>
        <span style={{ marginRight: "4px" }}>{statusIcon}</span>
        <Header isActive={hasLegalEntityRequirement}>Add your business details</Header>
      </Flexbox>
      {hasLegalEntityRequirement === true && (
        <Button marginLeft="auto" size="small" kind="primary" onClick={openLegalEntityForm}>
          Add details
        </Button>
      )}
      <Body>This allows us to verify your business.</Body>
    </Card>
  );
};

const Header = styled.span<{ isActive?: boolean }>`
  ${(props) => {
    if (props.isActive === true) {
      return css`
        ${props.theme.textStyles.Bold.Body300};
        color: ${props.theme.components.Card.Todo.Header.activeText};
      `;
    }

    return css`
      ${props.theme.textStyles.Regular.Body300}
      color: ${props.theme.components.Card.Todo.Header.text}
    `;
  }}
`;

const Body = styled.p`
  ${(props) => props.theme.textStyles.Regular.Body100};
  color: ${(props) => props.theme.components.Card.Todo.Body.text};
  width: 275px;
  margin-bottom: 0;
`;
