/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import React, { useEffect, useMemo } from "react";
import { FaChevronRight } from "react-icons/fa";

import { OldHorizontalRuleDontUse } from "~src/designSystem/deprecated/HorizontalRule";
import { OldSpanUseTextInstead } from "~src/designSystem/deprecated/Span";
import { Bolt } from "~src/designSystem/icons/Bolt";
import { Flexbox } from "~src/designSystem/layout/Flexbox";
import { Gridbox } from "~src/designSystem/layout/Gridbox";
import { useEnv } from "~src/shared/env/useEnv";
import { calculateBidPrice } from "~src/shared/helpers";
import { formatCents } from "~src/shared/helpers/format";
import { useNotNullContext } from "~src/shared/helpers/useNotNullContext";
import { ISegmentTrackEvent, useAnalytics } from "~src/shared/thirdParties/segment";
import { useSupportedCurrency } from "~src/vendor/utils/useSupportedCurrencies";

import { ProposalsContext } from "../../contexts/ProposalsContext";
import { IInboxStep, useInboxState } from "../../state";
import { AdjustableTermsScreen } from "./AdjustableTermsScreen";
import { AgreementCheckBox } from "./AgreementCheckBox";
import { BidPrice } from "./BidPrice";
import { OrderBoxSlider } from "./OrderBoxSlider";
import { IPayoutAccount, PayoutAccountsContext } from "./PayoutMethod/PayoutAccounts";
import { PayoutMethodDetails } from "./PayoutMethod/PayoutMethodDetails";
import { SelectPayoutMethod } from "./PayoutMethod/SelectPayoutMethod";
import { SubmitPayout } from "./SubmitPayout";
import { SuccessfulTrade } from "./SuccessfulTrade";

type ISummaryItem = {
  label: string;
  node: React.ReactNode;
};

export const SummaryTab: React.FC = () => {
  const currency = useSupportedCurrency();
  const wizardStep = useInboxState((state) => state.wizardStep);

  const { trackEvent } = useAnalytics();

  const { vendor, refetchVendor, accounts, payoutTo, setPayoutMethod } =
    React.useContext(PayoutAccountsContext);

  const env = useEnv();

  const isSandbox = env.DOMAIN.includes("pipe-sandbox");

  const [isSelectingPayoutMethod, setIsSelectingPayoutMethod] = React.useState<boolean>(false);
  const [isSelectingTermLength, setIsSelectingTermLength] = React.useState<boolean>(false);

  const { selectedProposals, payoutAmount, feeAmount, termLength, blendedBidPrice } =
    useNotNullContext(ProposalsContext);

  const defaultBidPrice = useMemo(() => {
    return calculateBidPrice(vendor?.rate_months_1, termLength);
  }, [vendor, termLength]);

  const topItems: ISummaryItem[] = [
    {
      label: "Bid Price",
      node: <BidPrice value={blendedBidPrice || defaultBidPrice} />,
    },
    { label: "Selected Contracts", node: selectedProposals.length },
    { label: "Amount", node: formatCents(payoutAmount, currency) },
  ];

  const bottomItems: ISummaryItem[] = [
    {
      label: "Pipe Fee",
      node: (
        <Flexbox row>
          {/* Show fee in sandbox for sales to demo; otherwise, waive fee by default */}
          {isSandbox ? (
            `(${formatCents(feeAmount, currency)})`
          ) : (
            <>
              <del>({formatCents(feeAmount, currency)})</del>
              {/* // TODO (ble): properly style with design system */}
              <OldSpanUseTextInstead color="warning" weight={500}>
                &nbsp;Waived
              </OldSpanUseTextInstead>
            </>
          )}
        </Flexbox>
      ),
    },
    {
      label: "Processing Time",
      node: (
        <Flexbox row alignItems="center">
          <Bolt />
          <OldSpanUseTextInstead color="title" marginLeft="8px" weight={500}>
            Instant
          </OldSpanUseTextInstead>
        </Flexbox>
      ),
    },
    { label: "Total Payout", node: formatCents(payoutAmount, currency) },
  ];

  useEffect(() => {
    if (wizardStep !== IInboxStep.INBOX) {
      setIsSelectingTermLength(false);
      setIsSelectingPayoutMethod(false);
    }
  }, [wizardStep]);

  if (isSelectingTermLength) {
    return <AdjustableTermsScreen setIsSelectingTermLength={setIsSelectingTermLength} />;
  }

  if (isSelectingPayoutMethod) {
    // If we are here, payoutTo is not undefined.
    return (
      <SelectPayoutMethod
        accounts={accounts ?? []}
        refetchVendor={refetchVendor}
        setIsSelectingPayoutMethod={setIsSelectingPayoutMethod}
        payoutTo={payoutTo as IPayoutAccount}
        setPayoutMethod={setPayoutMethod}
      />
    );
  }

  if (wizardStep === IInboxStep.COMPLETE) {
    return <SuccessfulTrade payoutAmount={payoutAmount} payoutTo={payoutTo} />;
  }

  return (
    <Flexbox paddingTop="20px" paddingBottom="20px">
      <Flexbox>
        <SelectableContainer
          row
          paddingY="8px"
          paddingX="32px"
          alignItems="center"
          mutable={wizardStep === IInboxStep.INBOX}
          onClick={() => {
            if (wizardStep === IInboxStep.INBOX) {
              setIsSelectingTermLength(true);
              trackEvent(ISegmentTrackEvent.TradingOrderBoxViewAdjustableTermsClick, {
                curTermLength: termLength,
              });
            }
          }}
        >
          <OldSpanUseTextInstead color="subtitle" size="14px" marginRight="auto">
            Max Term Length
          </OldSpanUseTextInstead>
          {/* <PayoutMethodDetails payoutTo={payoutTo} /> */}
          <OldSpanUseTextInstead color="title" weight={500} size="14px">
            {`${termLength} months`}
            {wizardStep === IInboxStep.INBOX && (
              <OldSpanUseTextInstead color="subtitle" weight={500} size="10px" marginLeft="8px">
                <FaChevronRight />
              </OldSpanUseTextInstead>
            )}
          </OldSpanUseTextInstead>
        </SelectableContainer>
        <Flexbox paddingY="8px" paddingX="32px" gap="16px">
          {topItems.map((item) => {
            return <SummaryTabRow key={item.label} item={item} />;
          })}
          {wizardStep === IInboxStep.INBOX ? (
            <Flexbox fullWidth padHalfBottom>
              <OrderBoxSlider />
            </Flexbox>
          ) : null}
        </Flexbox>

        <OldHorizontalRuleDontUse color="box" marginX="32px" />

        <Flexbox paddingY="16px" paddingX="32px" gap="16px">
          {bottomItems.map((item) => {
            return <SummaryTabRow key={item.label} item={item} />;
          })}
        </Flexbox>

        <OldHorizontalRuleDontUse color="box" marginX="32px" />

        {payoutTo !== undefined && (
          <SelectableContainer
            paddingY="8px"
            marginY="16px"
            paddingX="32px"
            row
            alignItems="center"
            mutable={wizardStep === IInboxStep.INBOX}
            onClick={() => wizardStep === IInboxStep.INBOX && setIsSelectingPayoutMethod(true)}
          >
            <OldSpanUseTextInstead
              color="subtitle"
              size="14px"
              css={css`
                margin-right: auto;
              `}
            >
              Payout to
            </OldSpanUseTextInstead>
            <PayoutMethodDetails payoutTo={payoutTo} />
            {wizardStep === IInboxStep.INBOX && (
              <OldSpanUseTextInstead color="subtitle" weight={500} marginLeft="12px">
                <Flexbox alignItems="center">
                  <FaChevronRight />
                </Flexbox>
              </OldSpanUseTextInstead>
            )}
          </SelectableContainer>
        )}
      </Flexbox>
      {wizardStep === IInboxStep.REVIEW && (
        <Flexbox paddingX="32px">
          <AgreementCheckBox />
        </Flexbox>
      )}
      <SubmitPayout />
    </Flexbox>
  );
};

const SummaryTabRow: React.FC<{ item: ISummaryItem }> = ({ item }) => {
  return (
    <Gridbox gridTemplateColumns="1.2fr 1fr">
      <OldSpanUseTextInstead color="subtitle" size="14px">
        {item.label}
      </OldSpanUseTextInstead>
      <OldSpanUseTextInstead
        color="title"
        size="14px"
        weight={500}
        textAlign="right"
        css={css`
          justify-self: flex-end;
        `}
      >
        {item.node}
      </OldSpanUseTextInstead>
    </Gridbox>
  );
};

const SelectableContainer = styled(Flexbox)<{ mutable: boolean }>`
  transition: ${(props) => props.theme.transition};
  &:hover {
    background: ${(props) => props.mutable && props.theme.components.OrderBox.Row.background.hover};
    cursor: ${(props) => props.mutable && "pointer"};
  }
`;
