import { sortBy } from "lodash";

import { IListRow, IListRowData } from "~src/shared/lists/types";
import { IListModel } from "~src/shared/lists/types/models";
import { calculatePayoutFromTradeableContract } from "~src/vendor/inbox/utils/adjustableTerms";

/**
 * Gets the proposals to slide
 * @param availableSubscriptions
 * @param maxRemainingPayoutLimit
 */
export const getSlideableProposals = (
  availableSubscriptions: IListRow<IListModel.lists_tradeable_contracts>[],
  maxRemainingPayoutLimit: number,
  selectedTermLength: number | undefined,
): IListRowData<IListModel.lists_tradeable_contracts>[] => {
  // Sort availableSubscriptions by payout_amount, from least to greatest
  const sortedSubs = sortBy(
    [...availableSubscriptions],
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    (sub) => calculatePayoutFromTradeableContract(sub.data, selectedTermLength) ?? 0,
  );

  const slideableProposals: IListRowData<IListModel.lists_tradeable_contracts>[] = [];

  // Take the proposals from sortedSubs, starting from index 0, until the cumulative payout_amount total is less than or equal to the maxRemainingPayoutLimit
  let currentSum = 0;
  for (let i = 0; i < sortedSubs.length; i += 1) {
    const sub = sortedSubs[i];
    if (sub === undefined) {
      continue;
    }

    const curSubPayout = calculatePayoutFromTradeableContract(sub.data, selectedTermLength);
    currentSum += curSubPayout;
    if (currentSum > maxRemainingPayoutLimit) {
      break;
    }

    // KLUDGE(johnrjj) - Slider expects a payout amount, monkey patching is the easiest way without a big refactor
    sub.data.base_payout = curSubPayout;

    slideableProposals.push(sub.data);
  }

  return slideableProposals;
};
