import * as Types from '../../../__generated__/graphql/types';

import { gql } from '@apollo/client';
export type createBankAccountOptions_account = { readonly __typename?: 'accounts', readonly public_id: string, readonly name: string, readonly mask: string, readonly subtype: string | null, readonly supports_money_movement: boolean, readonly is_excluded: boolean, readonly balance_current: number, readonly plaid_item: { readonly __typename?: 'plaid_items', readonly public_id: string, readonly institution_name: string, readonly institution_logo: string | null } | null };

export const fragment_createBankAccountOptions_account = gql`
    fragment createBankAccountOptions_account on accounts {
  public_id
  name
  mask
  subtype
  supports_money_movement
  is_excluded
  balance_current
  plaid_item {
    public_id
    institution_name
    institution_logo
  }
}
    `;