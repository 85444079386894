/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import styled from "@emotion/styled";
import React from "react";

import { Flexbox } from "~src/designSystem/layout/Flexbox";
import { Gridbox } from "~src/designSystem/layout/Gridbox";
import { useUser } from "~src/shared/auth/useUser";
import { useVendorDataSources } from "~src/shared/dataSources/useVendorDataSources";
import { IRequirement } from "~src/shared/dataSourcesRequirements/hooks/useFetchDataSourceRequirements";
import { TABLET_MAX } from "~src/shared/styles/responsive";

import { IVendorInboxState } from "../../utils/computeInboxState";
import { BeingReviewed } from "./BeingReviewed";
import { ConnectDataSourceCard } from "./ConnectDataSourceCard";
import { ConnectYourData } from "./ConnectYourData";
import { Declined } from "./Declined";
import { LegalEntityCard } from "./LegalEntityCard";

type IProps = {
  vendorInboxState: IVendorInboxState;
  requirements: IRequirement[];
  openBank: () => void;
  openAccounting: () => void;
  openBilling: () => void;
};

export const InboxDataSources: React.FC<IProps> = (props) => {
  const { vendorInboxState } = props;
  const { country } = useUser().vendor;

  if (vendorInboxState === IVendorInboxState.IN_REVIEW) {
    return <BeingReviewed country={country ?? undefined} />;
  }

  if (vendorInboxState === IVendorInboxState.DECLINED) {
    return <Declined country={country ?? undefined} />;
  }

  return (
    <InboxDataSourcesWrapper gap="80px" padding="32px" marginTop="64px" columns={2}>
      <Flexbox maxWidth="400px">
        <ConnectYourData country={country ?? undefined} />
      </Flexbox>
      <Flexbox>
        <DataSourceCards {...props} />
      </Flexbox>
    </InboxDataSourcesWrapper>
  );
};

export const DataSourceCards: React.FC<IProps> = (props) => {
  const { requirements, openBank, openAccounting, openBilling } = props;
  const { data } = useVendorDataSources();

  // All connected data sources by type, including the disconnected ones.
  const numConnectedDataSources = data.dataSources.reduce(
    (numSourcesMap, ds) => {
      if (ds.isAccounting) {
        numSourcesMap.accounting += 1;
      } else if (ds.isBank) {
        numSourcesMap.bank += 1;
      } else if (ds.isRevenue) {
        numSourcesMap.billing += 1;
      }
      return numSourcesMap;
    },
    { accounting: 0, bank: 0, billing: 0 },
  );

  // Initial data sources
  const numInitialDataSources = requirements
    .filter((req) => req.type === "initial")
    .reduce(
      (numSourcesMap, req) => {
        if (req.category === "accounting") {
          numSourcesMap.accounting += 1;
        } else if (req.category === "bank") {
          numSourcesMap.bank += 1;
        } else if (req.category === "revenue") {
          numSourcesMap.billing += 1;
        }
        return numSourcesMap;
      },
      { accounting: 0, bank: 0, billing: 0 },
    );

  // Additional data sources
  const numAdditionalDataSources = requirements
    .filter((req) => req.type === "additional")
    .reduce(
      (numSourcesMap, req) => {
        if (req.category === "accounting") {
          numSourcesMap.accounting += 1;
        } else if (req.category === "bank") {
          numSourcesMap.bank += 1;
        } else if (req.category === "revenue") {
          numSourcesMap.billing += 1;
        }
        return numSourcesMap;
      },
      { accounting: 0, bank: 0, billing: 0 },
    );

  // Disconnected data sources
  const numDisconnectedDataSources = data.dataSources
    .filter((ds) => ds.userActionRequiredAt != null)
    .reduce(
      (numSourcesMap, ds) => {
        if (ds.isAccounting) {
          numSourcesMap.accounting += 1;
        } else if (ds.isBank) {
          numSourcesMap.bank += 1;
        } else if (ds.isRevenue) {
          numSourcesMap.billing += 1;
        }
        return numSourcesMap;
      },
      { accounting: 0, bank: 0, billing: 0 },
    );

  // There are always 3 cards: bank, billing, accounting.
  const DATA_SOURCE_CARDS = [
    {
      headingText: "Connect your revenue",
      bodyText:
        "This allows us to automatically identify your recurring revenue and sync it with Pipe.",
      addSourceAction: openBilling,
      needsInitialLink: numInitialDataSources.billing > 0,
      needsAdditionalLink: numAdditionalDataSources.billing > 0,
      needsRelink: numDisconnectedDataSources.billing > 0,
      connectedBadgeLabel: `${numConnectedDataSources.billing}/${
        numConnectedDataSources.billing +
        numAdditionalDataSources.billing +
        numInitialDataSources.billing
      } added`,
      disconnectedBadgeLabel:
        numDisconnectedDataSources.billing > 0
          ? `${numDisconnectedDataSources.billing} disconnected`
          : undefined,
    },
    {
      headingText: "Connect your bank",
      bodyText: "This allows us to calculate your estimated burn rate and runway.",
      addSourceAction: openBank,
      needsInitialLink: numInitialDataSources.bank > 0,
      needsAdditionalLink: numAdditionalDataSources.bank > 0,
      needsRelink: numDisconnectedDataSources.bank > 0,
      connectedBadgeLabel: `${numConnectedDataSources.bank}/${
        numConnectedDataSources.bank + numAdditionalDataSources.bank + numInitialDataSources.bank
      } added`,
      disconnectedBadgeLabel:
        numDisconnectedDataSources.bank > 0
          ? `${numDisconnectedDataSources.bank} disconnected`
          : undefined,
    },

    {
      headingText: "Connect your accounting",
      bodyText: "This allows us to evaluate your financial health and determine a risk score.",
      addSourceAction: openAccounting,
      needsInitialLink: numInitialDataSources.accounting > 0,
      needsAdditionalLink: numAdditionalDataSources.accounting > 0,
      needsRelink: numDisconnectedDataSources.accounting > 0,
      connectedBadgeLabel: `${numConnectedDataSources.accounting}/${
        numConnectedDataSources.accounting +
        numAdditionalDataSources.accounting +
        numInitialDataSources.accounting
      } added`,
      disconnectedBadgeLabel:
        numDisconnectedDataSources.accounting > 0
          ? `${numDisconnectedDataSources.accounting} disconnected`
          : undefined,
    },
  ];

  return (
    <Flexbox gap="16px">
      {DATA_SOURCE_CARDS.map((cardProps, idx) => {
        return <ConnectDataSourceCard key={`${idx}`} {...cardProps} />;
      })}
      <LegalEntityCard />
    </Flexbox>
  );
};

const InboxDataSourcesWrapper = styled(Gridbox)`
  @media (max-width: ${TABLET_MAX}) {
    grid-template-columns: 100%;
    margin-top: 0;
  }
`;
