import React, { useEffect, useMemo } from "react";

import { Flexbox } from "~src/designSystem/layout/Flexbox";
import { Gridbox } from "~src/designSystem/layout/Gridbox";
import { useAuthContext } from "~src/shared/auth/AuthProvider";
import { useConnectAccounting } from "~src/shared/dataSources/accounting/hooks/useConnectAccounting";
import { useConnectBank } from "~src/shared/dataSources/bank/hooks/useConnectBank";
import { useConnectBilling } from "~src/shared/dataSources/billing/hooks/useConnectBilling";
import { useConnectDataSource } from "~src/shared/dataSources/connectDataSource/hooks/useConnectDataSource";
import { useOptimizelyTrackingForLegalEntities } from "~src/shared/dataSources/connectDataSource/hooks/useOptimizelyTrackingForLegalEntities";
import { useOnDataSourceRedirectSuccessEffect } from "~src/shared/dataSources/useOnDataSourceRedirectSuccessEffect";
import { useFetchDataSourceRequirements } from "~src/shared/dataSourcesRequirements/hooks/useFetchDataSourceRequirements";
import { ISegmentTrackPage, useAnalytics } from "~src/shared/thirdParties/segment";
import { useShowLegalEntityForm } from "~src/vendor/legalEntities/hooks/useShowLegalEntityForm";
import { LegalEntityFormHomeExistingVendor } from "~src/vendor/settingsLegalEntities/LegalEntityFormHomeExisting";

import { IInboxStep, useInboxState } from "../state";
import { computeInboxStateForVendor, IVendorInboxState } from "../utils/computeInboxState";
import { InboxDataSources } from "./DataSources";
import { InboxInfo } from "./InboxInfo";
import { InboxSelectionArea } from "./InboxSelectionArea";

export const InboxInner: React.FC = () => {
  const { trackPage } = useAnalytics();

  const wizardStep = useInboxState((state) => state.wizardStep);

  useEffect(() => {
    switch (wizardStep) {
      case IInboxStep.INBOX: {
        trackPage(ISegmentTrackPage.TradingTradePageImpression);
        break;
      }
      case IInboxStep.REVIEW: {
        trackPage(ISegmentTrackPage.TradingOrderSummaryPageImpression);
        break;
      }
      case IInboxStep.COMPLETE: {
        trackPage(ISegmentTrackPage.TradingOrderConfirmationImpression);
        break;
      }
      default: {
        break;
      }
    }
  }, [trackPage, wizardStep]);

  const { vendor } = useAuthContext();

  const { showForm, connections } = useShowLegalEntityForm();

  const {
    data: requirements,
    loading,
    error,
    refetch: refetchDataSourceRequirements,
  } = useFetchDataSourceRequirements();

  // If we have an error, throw it to the error boundary.
  if (error) {
    throw error;
  }

  const vendorInboxState = useMemo(() => {
    // Better error state would be nice at some point.
    if (vendor == null || requirements === undefined || loading) {
      return null;
    }
    return computeInboxStateForVendor(vendor, requirements);
  }, [vendor, requirements, loading]);

  const trackOptimizelyEvent = useOptimizelyTrackingForLegalEntities();

  const handleSuccess = React.useCallback(async (): Promise<void> => {
    trackOptimizelyEvent();
    await refetchDataSourceRequirements();
  }, [refetchDataSourceRequirements, trackOptimizelyEvent]);

  // Connect Data Source flow starter
  const openConnectDataSource = useConnectDataSource({
    onSuccess: handleSuccess,
    redirectPath: "/inbox",
    source: "trade-required-action-flow",
  });

  // Connect Plaid flow starter
  const { open: openPlaid } = useConnectBank({
    onSuccess: handleSuccess,
    redirectPath: "/inbox",
    source: "trade-required-action-flow",
  });

  // Connect Billing flow starter
  const openBilling = useConnectBilling({
    onSuccess: handleSuccess,
    redirectPath: "/inbox",
    source: "trade-required-action-flow",
  });

  // Connect Accounting flow starter
  const openAccounting = useConnectAccounting({
    onSuccess: handleSuccess,
    redirectPath: "/inbox",
    source: "trade-required-action-flow",
  });

  // Handle showing success screen for redirect-based integrations.
  useOnDataSourceRedirectSuccessEffect({
    currentRoute: "/inbox",
    openConnectDataSource,
  });

  return (
    <>
      {showForm && vendor && (vendor.canTrade || connections !== 1) && (
        <LegalEntityFormHomeExistingVendor />
      )}
      <>
        {!loading &&
        vendorInboxState != null &&
        vendorInboxState !== IVendorInboxState.CAN_TRADE ? (
          <Flexbox row justifyContent="center">
            <InboxDataSources
              vendorInboxState={vendorInboxState}
              requirements={requirements || []}
              openAccounting={openAccounting}
              openBank={openPlaid}
              openBilling={openBilling}
            />
          </Flexbox>
        ) : (
          <>
            {!loading && (
              <Gridbox gridTemplateColumns="1fr 1fr 400px" gridTemplateRows="auto 1fr" fullHeight>
                <InboxInfo />
                <InboxSelectionArea />
              </Gridbox>
            )}
          </>
        )}
      </>
    </>
  );
};
