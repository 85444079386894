import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

import { pluralizer } from "~src/shared/helpers";
import { formatCents } from "~src/shared/helpers/format";
import { IScheduleEntry } from "~src/vendor/requests";

dayjs.extend(utc);

export interface IPaymentScheduleGraphEntry {
  monthsFromToday: number;
  amount: number;
  count: number;
  label: string;
}
export interface IPaymentScheduleGraphData {
  currency: string;
  schedule: IPaymentScheduleGraphEntry[];
}

type IScheduleSummary = {
  count: number;
  payout: IScheduleEntry;
  payments: IScheduleEntry[];
};

const getMonthsFromToday = (ds: string): number => {
  const today = dayjs.utc();
  const date = dayjs.utc(ds);
  let months = (date.year() - today.year()) * 12;
  months -= today.month();
  months += date.month();
  return months <= 0 ? 0 : months;
};

export const calculatePaymentSchedule = (
  scheduleSummary: IScheduleSummary,
): IPaymentScheduleGraphData => {
  const { currency } = scheduleSummary.payout;
  const schedule = scheduleSummary.payments.map((payment) => {
    const date = dayjs.utc(payment.date);
    const label = `${pluralizer(payment.count, "Contract", "Contracts")}
    (${formatCents(payment.amount, payment.currency)})
    ${date.format("MMM")} ${date.format("YYYY")}`;
    return {
      label,
      monthsFromToday: getMonthsFromToday(payment.date),
      amount: payment.amount,
      count: payment.count,
    };
  });

  return { currency, schedule };
};
