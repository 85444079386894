import { css } from "@emotion/react";
import styled from "@emotion/styled";

import { OldButtonTryToAvoid } from "~src/designSystem/deprecated/OldButton";

export const SubmitButton: React.FC<React.ComponentProps<typeof OldButtonTryToAvoid>> = (props) => {
  return (
    <StyledButton {...props} primary size="large" fullWidth>
      {props.children}
    </StyledButton>
  );
};

const StyledButton = styled(OldButtonTryToAvoid)`
  color: ${(props) => props.theme.components.OrderBox.SubmitButton.text.default};

  ${(props) =>
    props.disabled === true
      ? css`
          color: ${props.theme.components.OrderBox.SubmitButton.text.disabled};
          border: 1px solid ${props.theme.components.OrderBox.SubmitButton.border.disabled};
          background-color: ${props.theme.components.OrderBox.SubmitButton.background.disabled};
        `
      : css`
          background-color: ${props.theme.components.OrderBox.SubmitButton.background.default};
          &:hover {
            background-color: ${props.theme.components.OrderBox.SubmitButton.background.hover};
          }
        `}
`;
