import styled from "@emotion/styled";
import React from "react";

import { StatusTag } from "~src/designSystem/atoms/StatusTag";
import {
  OldHeadingUseTextInstead,
  OldSubheadingUseTextInstead,
} from "~src/designSystem/deprecated/Heading";
import { Flexbox } from "~src/designSystem/layout/Flexbox";
import { Head } from "~src/designSystem/sortLater/Head";

export type IPageHeaderProps = {
  className?: string;
  /** sets the title of the page as well as heading */
  title: string;
  /** if you want to set the title but not change the tab's title  */
  pageHeading?: string;
  description?: React.ReactNode;
  /** if you want to add a tag, like "beta". if omitted, no tag will be shown.  */
  tagText?: string;
};

/** PageHeader component.
 * There should only be one per page.
 *
 * TODO(md): Can we design a better abstraction than this mishmash of implicit
 * behaviors?
 */
export const PageHeader: React.FC<IPageHeaderProps> = styled(
  ({ className, title, description, pageHeading, tagText }: IPageHeaderProps) => {
    return (
      <Flexbox className={className} maxWidth="600px">
        <Head title={title} />
        <Flexbox row alignItems="center">
          <OldHeadingUseTextInstead padHalfRight>{pageHeading ?? title}</OldHeadingUseTextInstead>
          {tagText !== undefined && (
            <Flexbox>
              <StatusTag color="orange">{tagText}</StatusTag>
            </Flexbox>
          )}
        </Flexbox>
        {typeof description === "string" ? (
          <OldSubheadingUseTextInstead size="13px">{description}</OldSubheadingUseTextInstead>
        ) : (
          description
        )}
      </Flexbox>
    );
  },
)``;
