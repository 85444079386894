import styled from "@emotion/styled";
import { useFocusRing } from "@react-aria/focus";
import { useListBox, useOption } from "@react-aria/listbox";
import { mergeProps } from "@react-aria/utils";
import { ListState, useListState } from "@react-stately/list";
import React from "react";

export type IListBoxProps = {
  label?: React.ReactNode;
  children: React.ReactElement | React.ReactElement[];
  selectedKeys: Iterable<React.Key>;
  onSelectionChange: (keys: Set<React.Key>) => void;
  selectionMode?: "single" | "none" | "multiple";
};

/**
 * This is a preliminary ListBox component. It is used in the Select component.
 *
 * It currently only supports:
 *
 * - single select
 * - a controlled API
 *
 * Future work will involve expanding the API to support react-aria's full
 * feature set.
 *
 * See the react-aria docs for more information:
 * https://react-spectrum.adobe.com/react-aria/useListBox.html.
 */
export const ListBox: React.FC<IListBoxProps> = (props) => {
  const state = useListState(props);

  return (
    <StatelessListBox state={state} selectionMode={props.selectionMode} label={props.label}>
      {props.children}
    </StatelessListBox>
  );
};

type IStatelessListBoxProps = {
  state: ListState<unknown>;
  label?: React.ReactNode;
  children?: React.ReactElement | React.ReactElement[];
  items?: Iterable<unknown>;
  selectionMode?: "single" | "none" | "multiple";

  /* TODO: Is a negative prop idiomatic? */
  /** Whether to style the active option differently from the rest. */
  dontStyleActive?: boolean;
} & IListBoxULProps;

/**
 * This is a provide-your-own-state version of the ListBox for consumption by other
 * Design System components.
 */
export const StatelessListBox: React.FC<IStatelessListBoxProps> = (props) => {
  const ref = React.useRef(null);

  const { listBoxProps, labelProps } = useListBox(props, props.state, ref);

  return (
    <ListBoxWrapper borderRadius={props.borderRadius}>
      {props.label !== undefined && <ListBoxLabel {...labelProps}>{props.label}</ListBoxLabel>}
      <ListBoxUL {...listBoxProps} ref={ref}>
        {[...props.state.collection].map((item) => (
          <ListOption
            dontStyleActive={props.dontStyleActive ?? false}
            key={item.key}
            item={item}
            state={props.state}
          />
        ))}
      </ListBoxUL>
    </ListBoxWrapper>
  );
};

/* TODO: These should be part of a set of props. How to type this? */
const ListBoxWrapper = styled.div<{ borderRadius?: string }>`
  border-radius: ${(props) => props.borderRadius};
  overflow: hidden;
`;

const ListBoxLabel = styled.div`
  color: ${(props) => props.theme.components.ListBox.headerLabelTextColor};
  font-weight: ${(props) => props.theme.components.ListBox.font.weight};
  font-size: ${(props) => props.theme.components.ListBox.font.size};
  line-height: ${(props) => props.theme.components.ListBox.font.lineHeight};

  background-color: ${(props) => props.theme.components.ListBox.headerLabelBgColor};
  padding: ${(props) => `${props.theme.spacing.xs} ${props.theme.spacing.md}`};
`;

type IListBoxULProps = {
  borderRadius?: string;
};

const ListBoxUL = styled.ul<IListBoxULProps>`
  // TODO(md): Should this be done at CSS normalization level?
  list-style-type: none;
  li {
    list-style-type: none;
  }

  & > :first-child {
    border-top-left-radius: ${(props) => props.borderRadius};
    border-top-right-radius: ${(props) => props.borderRadius};
  }
  & > :last-child {
    border-bottom-left-radius: ${(props) => props.borderRadius};
    border-bottom-right-radius: ${(props) => props.borderRadius};
  }
`;

type IListOptionProps = {
  item: {
    /** A unique key for the node. */
    "key": React.Key;

    /** An accessibility label for this node. */
    "aria-label"?: string;

    /** The rendered contents of this node (e.g. JSX). */
    "rendered": React.ReactNode;
  };
  // TODO: Generics?
  state: ListState<unknown>;
  dontStyleActive: boolean;
};

const ListOption: React.FC<IListOptionProps> = (props) => {
  const ref = React.useRef(null);

  const { optionProps, labelProps, descriptionProps, isSelected, isDisabled } = useOption(
    { key: props.item.key },
    props.state,
    ref,
  );
  const { isFocusVisible, focusProps } = useFocusRing();

  const liProps = {
    ...mergeProps(optionProps, focusProps),
    ref,
    isSelected,
    isFocusVisible,
    isDisabled,
  };

  // We support both title+description complex list items and simple text list items.
  if (Array.isArray(props.item.rendered)) {
    const [label, description] = props.item.rendered;

    // TODO: Must we cast here?
    return (
      <ListOptionListItem dontStyleActive={props.dontStyleActive} {...liProps}>
        {React.cloneElement(label as React.ReactElement, labelProps)}
        {React.cloneElement(description as React.ReactElement, descriptionProps)}
      </ListOptionListItem>
    );
  }

  return (
    <ListOptionListItem dontStyleActive={props.dontStyleActive} {...liProps}>
      {props.item.rendered}
    </ListOptionListItem>
  );
};

type IListOptionListItemProps = {
  isSelected: boolean;
  isFocusVisible: boolean;
  isDisabled: boolean;
  dontStyleActive: boolean;
};

const ListOptionListItem = styled.li<IListOptionListItemProps>`
  cursor: ${(props) => !props.isDisabled && "pointer"};
  padding: ${(props) => `${props.theme.spacing.xs} ${props.theme.spacing.md}`};
  background-color: ${(props) => {
    if (props.isSelected && !props.dontStyleActive) {
      return props.theme.components.ListBox.itemActiveBgColor;
    }
    return props.theme.components.ListBox.itemBgColor;
  }};

  &:focus {
    outline: none;
    background: ${(props) => props.theme.components.ListBox.itemBgColorHover};
  }

  &:hover {
    /* Continue to use the base background color if item is selected. */
    background-color: ${(props) =>
      !(props.isSelected && !props.dontStyleActive) &&
      props.theme.components.ListBox.itemBgColorHover};
  }
`;
