/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React, { useCallback } from "react";

import { permission_enum_enum } from "~src/__generated__/graphql/types";
import { Tooltip } from "~src/designSystem/atoms/Tooltip";
import { Flexbox } from "~src/designSystem/layout/Flexbox";
import { useEnv } from "~src/shared/env/useEnv";
import { useNotNullContext } from "~src/shared/helpers/useNotNullContext";
import { useHasPermission } from "~src/shared/permissions";
import { useCallRequestLegacyHammerMode } from "~src/shared/requests/useRequest";
import { useOptimizelyTrack } from "~src/shared/thirdParties/optimizely";
import { ISegmentTrackEvent, useAnalytics } from "~src/shared/thirdParties/segment";
import { IInboxStep, useInboxState } from "~src/vendor/inbox/state";
import { vendorRequests } from "~src/vendor/requests";

import { ProposalsContext } from "../../contexts/ProposalsContext";
import { PayoutAccountsContext } from "./PayoutMethod/PayoutAccounts";
import { SubmitButton } from "./SubmitButton";

export interface SubmitPayoutProps {
  onReviewOrderClick?: () => void;
}

export const SubmitPayout: React.FC<SubmitPayoutProps> = (props) => {
  const { selectedProposalIDs, remainingSelectedPayoutLimit, payoutAmount, termLength } =
    useNotNullContext(ProposalsContext);

  const wizardStep = useInboxState((state) => state.wizardStep);
  const setWizardStep = useInboxState((state) => state.setWizardStep);
  const isTermsChecked = useInboxState((state) => state.isTermsChecked);
  const setIsTermsChecked = useInboxState((state) => state.setIsTermsChecked);
  const isOrderSubmissionLoading = useInboxState((state) => state.isOrderSubmissionLoading);
  const setIsOrderSubmissionLoading = useInboxState((state) => state.setIsOrderSubmissionLoading);

  const { trackEvent } = useAnalytics();
  const trackEventOptimizely = useOptimizelyTrack();

  const { vendor, refetchVendor, payoutTo } = React.useContext(PayoutAccountsContext);

  const callRequest = useCallRequestLegacyHammerMode();

  const isSandbox = useEnv().DOMAIN.includes("pipe-sandbox");

  const handlePayoutSubmit = async () => {
    setIsOrderSubmissionLoading(true);
    // If we have set a payout method, update the vendor's default
    // payout method in the database.
    if (vendor !== undefined && payoutTo !== undefined) {
      const res = await callRequest(
        vendorRequests.updateVendor({
          publicID: vendor.public_id,
          payoutMethodPublicID: payoutTo.public_id,
        }),
      );
      // If the request fails, abort the payout submission.
      if (!res.ok) {
        return;
      }
      refetchVendor();
    }

    // Now pipe the subscriptions. In the sandbox environment we cannot pass the
    // expectedPayout parameter or the backend, which does not assess a pipe
    // fee, will calculate a different payout and the trade will fail.
    const requestData: {
      revenueStreamIDs: readonly string[];
      expectedPayout?: number;
      termLength: number;
    } = {
      revenueStreamIDs: selectedProposalIDs,
      expectedPayout: payoutAmount,
      termLength,
    };
    if (isSandbox) {
      requestData.expectedPayout = undefined;
    }

    const res = await callRequest(vendorRequests.enqueueTrade(requestData));
    if (res.ok) {
      setIsTermsChecked(false);
      setWizardStep(IInboxStep.COMPLETE);
    }

    setIsOrderSubmissionLoading(false);
    // Track the event in segment
    trackEvent(ISegmentTrackEvent.TradingOrderSummaryPagePayoutButtonClick);
    trackEvent(ISegmentTrackEvent.TradingOrderSummaryPagePayoutButtonClick);
    trackEventOptimizely(ISegmentTrackEvent.TradingOrderSummaryPagePayoutButtonClick);
  };

  const { onReviewOrderClick } = props;

  const handleReviewOrderClick = useCallback(() => {
    onReviewOrderClick?.();

    setIsOrderSubmissionLoading(true);
    setWizardStep(IInboxStep.REVIEW);
    setIsOrderSubmissionLoading(false);
    // Track the event
    trackEvent(ISegmentTrackEvent.TradingPageReviewOrderButtonClick);
    trackEventOptimizely(ISegmentTrackEvent.TradingPageReviewOrderButtonClick);
  }, [
    onReviewOrderClick,
    setIsOrderSubmissionLoading,
    setWizardStep,
    trackEvent,
    trackEventOptimizely,
  ]);

  const isSelectedSubscriptionsEmpty = selectedProposalIDs.length === 0;

  const canTrade = useHasPermission(permission_enum_enum.action__trade_contracts);

  return (
    <Flexbox fullWidth paddingTop="20px" paddingX="16px">
      <Flexbox padHalfX>
        {(() => {
          // This is not ideal from a UX standpoint, but we must make do with our
          // current inbox.
          if (!canTrade) {
            return (
              <SubmitButton className="trade-review" disabled>
                Not authorized to trade
              </SubmitButton>
            );
          }
          if (wizardStep === IInboxStep.INBOX) {
            return (
              <Tooltip
                tooltipContent={
                  remainingSelectedPayoutLimit < 0 ? "You’re above your payout limit." : null
                }
              >
                <Flexbox
                  css={css`
                    position: relative;
                  `}
                >
                  <SubmitButton
                    className="trade-review"
                    onClick={handleReviewOrderClick}
                    isLoading={isOrderSubmissionLoading}
                    disabled={isSelectedSubscriptionsEmpty || remainingSelectedPayoutLimit <= 0}
                  >
                    Review Order
                  </SubmitButton>
                </Flexbox>
              </Tooltip>
            );
          }

          if (wizardStep === IInboxStep.REVIEW) {
            return (
              <SubmitButton
                className="trade-payout"
                key={isTermsChecked.toString()}
                onClick={handlePayoutSubmit}
                disabled={
                  !isTermsChecked ||
                  isSelectedSubscriptionsEmpty ||
                  remainingSelectedPayoutLimit <= 0
                }
                isLoading={isOrderSubmissionLoading}
              >
                Payout
              </SubmitButton>
            );
          }
        })()}
      </Flexbox>
    </Flexbox>
  );
};
