/** @jsxImportSource @emotion/react */
import { css, useTheme } from "@emotion/react";
import dynamic from "next/dynamic";
import React from "react";

import { OldHeadingUseTextInstead } from "~src/designSystem/deprecated/Heading";
import { ReallyOldTextDontTouchAtAll } from "~src/designSystem/deprecated/Text";
import { Bolt } from "~src/designSystem/icons/Bolt";
import { Flex } from "~src/designSystem/layout/Flex";
import { Flexbox } from "~src/designSystem/layout/Flexbox";
import { Gridbox } from "~src/designSystem/layout/Gridbox";
import { Spacer } from "~src/designSystem/layout/Spacer";
import { formatCents } from "~src/shared/helpers/format";
import { useSupportedCurrency } from "~src/vendor/utils/useSupportedCurrencies";

import { Drawer } from "./Drawer";
import { IPayoutAccount } from "./PayoutMethod/PayoutAccounts";
import { PayoutMethodDetails } from "./PayoutMethod/PayoutMethodDetails";
import { PayoutSuccessImgPlaceholder } from "./PayoutSuccessImgPlaceholder";

const PayoutSuccessImg = dynamic(
  async () => (await import("~src/shared/animations/PayoutSuccessImg")).PayoutSuccessImg,
  { loading: PayoutSuccessImgPlaceholder },
);

// eager load the payout image
const payoutRendered = <PayoutSuccessImg size="250px" />;

interface IProps {
  payoutAmount: number;
  payoutTo?: IPayoutAccount;
}

export const SuccessfulTrade: React.FC<IProps> = (props) => {
  const currency = useSupportedCurrency();
  const theme = useTheme();

  const items = [
    {
      label: "Processing Time",
      node: (
        <Flexbox row alignItems="center">
          <Bolt />
          <Spacer x="6px" />
          <ReallyOldTextDontTouchAtAll color="#FFFFFF" weight="500">
            Instant
          </ReallyOldTextDontTouchAtAll>
        </Flexbox>
      ),
    },
  ];

  if (props.payoutTo !== undefined) {
    items.push({
      label: "Payout Account",
      node: (
        <Flexbox row alignItems="center" justifyContent="flex-end">
          <PayoutMethodDetails payoutTo={props.payoutTo} />
        </Flexbox>
      ),
    });
  }

  return (
    <Flexbox
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      padX
      padTop
      padBottom
    >
      <Flexbox padHalfX>
        <Drawer isOpen css={drawerCss}>
          <Flex direction="column" align="center">
            {payoutRendered}
            <OldHeadingUseTextInstead bold color="title">
              {formatCents(props.payoutAmount, currency)}
            </OldHeadingUseTextInstead>
            <ReallyOldTextDontTouchAtAll color="#656565" weight="500" size="14px">
              Successfully Traded
            </ReallyOldTextDontTouchAtAll>
          </Flex>
        </Drawer>
        {items.map((item) => {
          return (
            <Gridbox key={item.label} gridTemplateColumns="1fr 1fr" oldMarginBottom>
              <ReallyOldTextDontTouchAtAll
                color={theme.components.OrderBox.SuccessfulTrade.label.default}
                size="14px"
              >
                {item.label}
              </ReallyOldTextDontTouchAtAll>
              <ReallyOldTextDontTouchAtAll
                color="#FFF"
                size="14px"
                weight="500"
                textAlign="right"
                css={css`
                  justify-self: flex-end;
                `}
              >
                {item.node}
              </ReallyOldTextDontTouchAtAll>
            </Gridbox>
          );
        })}
      </Flexbox>
    </Flexbox>
  );
};

const drawerCss = css`
  margin-bottom: 40px;
  background: none;
`;
