import { useCallback, useEffect, useState } from "react";

import { useOptimizelyTrack } from "~src/shared/thirdParties/optimizely";
import { ISegmentTrackEvent, useAnalytics } from "~src/shared/thirdParties/segment";

import { useDataSourcesQuery } from "./__generated__/useOptimizelyTrackingForLegalEntities";

export const useOptimizelyTrackingForLegalEntities = (): (() => void) => {
  const [newConnection, setNewConnection] = useState(false);

  const track = useOptimizelyTrack();
  const { trackEvent } = useAnalytics();

  const { data, loading, refetch } = useDataSourcesQuery({ notifyOnNetworkStatusChange: true });

  useEffect(() => {
    if (newConnection && !loading && data && data.data_sources.length === 2) {
      if (data.data_sources[0] && data.data_sources[1]) {
        setNewConnection(false);
        trackEvent(ISegmentTrackEvent.VendorSecondDataSourceConnected);
        track("second_data_source_connected");
        const t1 = new Date(data.data_sources[0].created_at).getTime();
        const t2 = new Date(data.data_sources[1].created_at).getTime();
        const sevenDays = 7 * 24 * 60 * 60 * 1000;
        if (t2 - t1 <= sevenDays) {
          track("7d_second_data_source_connected");
        }
      }
    }
  }, [newConnection, data, loading, track, trackEvent]);

  const trackOptimizelyEvent = useCallback(async () => {
    await refetch();
    setNewConnection(true);
  }, [refetch]);

  return trackOptimizelyEvent;
};

graphql`
  query DataSourcesQuery {
    data_sources(order_by: { created_at: asc }) {
      created_at
    }
  }
`;
