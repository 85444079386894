import { useMemo } from "react";

import { ITableData } from "../types";

export const useArrayAsTableData = (
  array: Record<string, unknown>[],
  /**
   * A function to generate each row's unique ID. This is necessary to have correct
   * re-renders upon changes to the array.
   *
   * The table component populates the table by fetching rows by their index. It
   * memoizes displayed rows by the row's ID. If the underlying array data changes, but
   * the ID doesn't change, the row will not be re-rendered.
   *
   * For this reason, a row's ID should change if the data inside that row changes.
   * Simply using the row's index as the ID doesn't work, since the row's index doesn't
   * change if the row changes.
   */
  keyGenerator: (index: number) => string,
  loading = false,
  /**
   * Manually specify the number of loading rows to render while waiting for the table
   * to populate.
   *
   * This currently exists to make the statements table fetch not bad. Perhaps it will
   * be used by more things in the future. I discourage it; at that point, we should
   * try to make a list.
   */
  loadingCount = 0,
): ITableData => {
  return useMemo(
    () => ({
      loading,
      count: loading ? loadingCount : array.length,
      getRowByIndex: (index: number) => {
        if (loading) {
          return null;
        }

        return {
          index,
          id: keyGenerator(index),
          data: array[index] ?? {},
        };
      },
      loadRows: async () => {},
      reset: () => {},
    }),
    [array, keyGenerator, loading, loadingCount],
  );
};
