import { css, useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { useRouter } from "next/router";
import React from "react";

import { Button } from "~src/designSystem/atoms/Button";
import { TwoPartCard } from "~src/designSystem/atoms/TwoPartCard";
import { Flexbox } from "~src/designSystem/layout/Flexbox";

import { ConnectionStatusBadge } from "./ConnectionStatusBadge";
import { CircleCheck } from "./icons/CircleCheck";
import { CirclePlus } from "./icons/CirclePlus";
import { CircleWarning } from "./icons/CircleWarning";

type IConnectDataSourceCardProps = {
  headingText: string;
  bodyText: string;
  needsInitialLink: boolean;
  needsAdditionalLink: boolean;
  needsRelink: boolean;
  addSourceAction: () => void;
  connectedBadgeLabel: string;
  disconnectedBadgeLabel?: string;
};
export const ConnectDataSourceCard: React.FC<IConnectDataSourceCardProps> = (props) => {
  const {
    headingText,
    bodyText,
    addSourceAction,
    connectedBadgeLabel,
    disconnectedBadgeLabel,
    needsAdditionalLink,
    needsInitialLink,
    needsRelink,
  } = props;

  const theme = useTheme();
  const router = useRouter();

  const statusIcon = (() => {
    if (needsAdditionalLink || needsRelink) {
      return <CircleWarning />;
    }
    if (needsInitialLink) {
      return <CirclePlus />;
    }
    return <CircleCheck />;
  })();

  return (
    <TwoPartCard
      isActive={needsInitialLink || needsAdditionalLink || needsRelink}
      justifyContent="space-between"
      maxWidth="448px"
      flexWrap="wrap"
    >
      <Flexbox row flexWrap="wrap" padding={theme.spacing.lg}>
        <span style={{ marginRight: "4px", marginLeft: "-2px" }}>{statusIcon}</span>
        <Header isActive={needsInitialLink || needsAdditionalLink || needsRelink}>
          {headingText}
        </Header>
        <Button
          marginLeft="auto"
          size="small"
          kind={needsInitialLink || needsAdditionalLink ? "primary" : "secondary"}
          onClick={addSourceAction}
        >
          Add source
        </Button>
        <Body>{bodyText}</Body>
      </Flexbox>
      <Flexbox
        row
        gap="6px"
        paddingLeft={theme.spacing.lg}
        paddingRight={theme.spacing.lg}
        paddingTop={theme.spacing.xs}
        paddingBottom={theme.spacing.xs}
      >
        <ConnectionStatusBadge label={connectedBadgeLabel} />
        {disconnectedBadgeLabel !== undefined && (
          <ConnectionStatusBadge
            label={disconnectedBadgeLabel}
            variant="warning"
            onClick={() => router.push("/settings/data-sources")}
          />
        )}
      </Flexbox>
    </TwoPartCard>
  );
};

const Header = styled.span<{ isActive?: boolean }>`
  ${(props) => {
    if (props.isActive === true) {
      return css`
        ${props.theme.textStyles.Bold.Body300};
        color: ${props.theme.components.Card.Todo.Header.activeText};
      `;
    }

    return css`
      ${props.theme.textStyles.Regular.Body300}
      color: ${props.theme.components.Card.Todo.Header.text}
    `;
  }}
`;

const Body = styled.p`
  ${(props) => props.theme.textStyles.Regular.Body100};
  color: ${(props) => props.theme.components.Card.Todo.Body.text};
  width: 275px;
  margin-bottom: 0;
`;
