import { useCallback, useMemo } from "react";

import { useAuthContext } from "~src/shared/auth/AuthProvider";
import { useUser } from "~src/shared/auth/useUser";

import { UseLegalEntities, useUseLegalEntities } from "./__generated__/useLegalEntity";

export type ILegalEntity = UseLegalEntities["vendors"][number]["legal_entities"][number];

type ILegalEntityRequirement = {
  entity?: ILegalEntity;
  required: boolean;
};

export type IUseLegalEntities = {
  requirement?: ILegalEntityRequirement;
  loading: boolean;
  refetch: () => Promise<void>;
};

export const useFetchLegalEntity = (vID?: string): IUseLegalEntities => {
  const { vendor } = useAuthContext();
  const vendorID = vID !== undefined ? vID : vendor ? vendor.publicID : "";
  const { data, loading, refetch } = useUseLegalEntities({
    variables: { vendorID },
  });
  const user = useUser();
  const doRefetch = useCallback(async () => {
    await refetch();
  }, [refetch]);

  const makeEntity = (d?: UseLegalEntities): ILegalEntity | undefined => {
    if (d === undefined) {
      return undefined;
    }
    return d.vendors[0]?.legal_entities[0];
  };

  return useMemo(() => {
    return {
      requirement: {
        entity: makeEntity(data),
        required: user.vendor.country === "US",
      },
      loading,
      refetch: doRefetch,
    };
  }, [data, loading, user, doRefetch]);
};

graphql`
  query UseLegalEntities($vendorID: String!) {
    vendors(where: { public_id: { _eq: $vendorID } }) {
      public_id

      legal_entities(order_by: { created_at: asc }) {
        public_id
        address1
        address2
        city
        country
        entity_type
        name
        postal_code
        state
        tin
        state_of_incorporation
        middesk_business_status
      }
    }
  }
`;
