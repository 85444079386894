import { ISelectOption } from "~src/designSystem/deprecated/OldSelect";

import { createBankAccountOptions_account } from "./__generated__/createBankAccountOptions";
import { BankAccountInfo } from "./BankAccountInfo";

export type IBankAccount = createBankAccountOptions_account;

export const createBankAccountOptions = (
  accounts: readonly IBankAccount[],
): readonly ISelectOption[] =>
  accounts
    .slice()
    .filter(isValidBankAccountOption)
    .sort(compareBankAccountOptions)
    .map((account) => ({
      value: account.public_id,
      label: BankAccountInfo({ account }),
    }));

const compareBankAccountOptions = (a: IBankAccount, b: IBankAccount): -1 | 0 | 1 => {
  const bankA = a.plaid_item?.institution_name ?? "";
  const bankB = b.plaid_item?.institution_name ?? "";
  if (bankA < bankB) return -1;
  if (bankA > bankB) return 1;
  if (a.name < b.name) return -1;
  if (a.name > b.name) return 1;
  if (a.mask < b.mask) return -1;
  if (a.mask > b.mask) return 1;
  return 0;
};

export const isValidBankAccountOption = (
  account: Pick<IBankAccount, "is_excluded" | "supports_money_movement">,
): boolean => !account.is_excluded && account.supports_money_movement;

graphql`
  fragment createBankAccountOptions_account on accounts {
    public_id
    name
    mask
    subtype
    supports_money_movement
    is_excluded
    balance_current
    plaid_item {
      public_id
      institution_name
      institution_logo
    }
  }
`;
