import React, { useEffect } from "react";

import { useStepper } from "~src/shared/stepper/stepperContext";
import { ISegmentTrackEvent, useAnalytics } from "~src/shared/thirdParties/segment";
import { LegalEntityFormHome } from "~src/vendor/settingsLegalEntities/LegalEntityFormHome";

export const LegalEntityFormHomeExistingVendor: React.FC = () => {
  const { addAndOpenStepperDialog, clearStepperStack } = useStepper();
  const { trackEvent } = useAnalytics();
  const storedTime = localStorage.getItem("has_seen_legal_entity_form");
  const currentTime = new Date().getTime();

  const hasSeenToday =
    storedTime !== null && currentTime - parseInt(storedTime, 10) < 1000 * 60 * 60 * 24;

  useEffect(() => {
    if (!hasSeenToday) {
      addAndOpenStepperDialog({
        component: <LegalEntityFormHome onSubmit={clearStepperStack} />,
        config: {
          title: "Legal entity details",
          onCloseOverride: () => {
            trackEvent(ISegmentTrackEvent.VendorLegalEntityFormInboxClosed);
            const time = new Date().getTime();
            localStorage.setItem("has_seen_legal_entity_form", String(time));
            clearStepperStack();
          },
        },
      });
    }
  }, [addAndOpenStepperDialog, clearStepperStack, hasSeenToday, trackEvent]);

  return null;
};
