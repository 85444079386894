import { useRouter } from "next/router";
import React from "react";

import { Success } from "~src/designSystem/icons/Success";

import { useStepper } from "../stepper/stepperContext";
import { DataSourceInformationLayout } from "./components/dataSourceInformationLayout";

type IUseOnDataSourceRedirectSuccessEffectArgs = {
  currentRoute: string;
  openConnectDataSource: () => void;
};

export const useOnDataSourceRedirectSuccessEffect = (
  args: IUseOnDataSourceRedirectSuccessEffectArgs,
): void => {
  const router = useRouter();
  const { query } = router;
  const { addAndOpenStepperDialog, clearStepperStack } = useStepper();

  // Handle showing success screen for redirect-based integrations.
  React.useEffect(() => {
    // Look for Codat, Stripe, PayPal, or Gocardless in the query params and derive the
    // success message heading text.
    const headingText: string | null = (() => {
      if (query.codat != null) {
        return "Accounting platform";
      }
      if (query.stripe != null) {
        return "Stripe";
      }
      if (query.gocardless != null) {
        return "GoCardless";
      }
      if (query.paypal != null) {
        return "PayPal";
      }
      return null;
    })();

    const dataTypeText: string = (() => {
      if (query.codat != null) {
        return "Accounting data";
      }
      if (query.stripe != null || query.gocardless != null || query.paypal != null) {
        return "Revenue streams";
      }
      return "";
    })();

    // If Codat, Gocardless, PayPal, or Stripe found in the query params, show the success screen.
    if (headingText !== null) {
      addAndOpenStepperDialog({
        component: (
          <DataSourceInformationLayout
            heading={`${headingText} has been linked`}
            description={`Nicely done! You’ve successfully added your ${dataTypeText}. Connect or upload additional data sources to Pipe to ensure you qualify for the highest possible bids and trade limits.`}
            imageSrc={Success}
            primaryButtonText="Add more"
            primaryButtonAction={args.openConnectDataSource}
            secondaryButtonText="Done"
            secondaryButtonAction={clearStepperStack}
          />
        ),
        config: {
          title: `${dataTypeText} added `,
        },
      });
      // Redirect to the same page - currentRoute - so that "?codat=true", "?stripe=true",
      // "?gocardless=true", or "paypal=true" set by corresponding _callback.tsx files gets
      // cleared and the user doesn't see the success screen again if they reload the page.
      // This "redirect" is a noop and just clears the query strings from the url.
      router.replace(args.currentRoute);
    }
  }, [addAndOpenStepperDialog, router, query, clearStepperStack, args]);
};
