import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const Touchable = styled.div<{ selected?: boolean }>`
  width: fit-content;
  padding: 10px;
  margin-right: 12px;
  border-radius: ${({ theme }) => theme.borderRadii.sm};
  cursor: pointer;
  color: ${({ theme }) => theme.components.Sidebar.SubnavLink.text.inactive};
  font-weight: ${({ theme }) => theme.touchable.font.weight};
  ${({ selected, theme }) =>
    selected === true &&
    css`
      background: ${theme.components.Sidebar.SubnavLink.background.active};
      color: ${theme.touchable.text.active};
    `}
  transition: all 0.2s ease;
  &:hover {
    background: ${({ theme }) => theme.components.Sidebar.SubnavLink.background.hover};
    color: ${({ theme }) => theme.components.Sidebar.SubnavLink.text.active};
  }
`;
