import { Context, useContext } from "react";

// useNotNullContext is a wrapper around useContext that asserts the value in the
// context is not null.
export const useNotNullContext = <T>(context: Context<T | null>): T => {
  const data = useContext(context);
  if (data === null) {
    throw new Error("Used context outside of a provider.");
  }
  return data;
};
