import { useMemo } from "react";
import { useGetSet } from "react-use";

export type IUseListCheckable = {
  getNumSelected: () => number;
  getSelected: () => string[];
  toggleSelected: (id: string) => void;
  isSelected: (id: string) => boolean;
  updateSelections: (ids: string[]) => void;
  reset: () => void;
};

export const useListCheckable = (): IUseListCheckable => {
  const [getSelected, setSelected] = useGetSet<Set<string>>(new Set());

  return useMemo(() => {
    return {
      getNumSelected: () => getSelected().size,
      getSelected: () => Array.from(getSelected()),
      toggleSelected: (id: string) => {
        const newSelected = new Set(getSelected());
        if (newSelected.has(id)) {
          newSelected.delete(id);
        } else {
          newSelected.add(id);
        }
        setSelected(newSelected);
      },
      isSelected: (id: string) => getSelected().has(id),
      reset: () => setSelected(new Set()),
      updateSelections: (ids: string[]) => {
        setSelected(new Set(ids));
      },
    };
  }, [getSelected, setSelected]);
};
