import * as Types from '../../../../../__generated__/graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DataSourcesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type DataSourcesQuery = { readonly __typename?: 'query_root', readonly data_sources: ReadonlyArray<{ readonly __typename?: 'data_sources', readonly created_at: string }> };


export const DataSourcesQueryDocument = gql`
    query DataSourcesQuery {
  data_sources(order_by: {created_at: asc}) {
    created_at
  }
}
    `;

/**
 * __useDataSourcesQuery__
 *
 * To run a query within a React component, call `useDataSourcesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDataSourcesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDataSourcesQuery({
 *   variables: {
 *   },
 * });
 */
export function useDataSourcesQuery(baseOptions?: Apollo.QueryHookOptions<DataSourcesQuery, DataSourcesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DataSourcesQuery, DataSourcesQueryVariables>(DataSourcesQueryDocument, options);
      }
export function useDataSourcesQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DataSourcesQuery, DataSourcesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DataSourcesQuery, DataSourcesQueryVariables>(DataSourcesQueryDocument, options);
        }
export type DataSourcesQueryHookResult = ReturnType<typeof useDataSourcesQuery>;
export type DataSourcesQueryLazyQueryHookResult = ReturnType<typeof useDataSourcesQueryLazyQuery>;
export type DataSourcesQueryQueryResult = Apollo.QueryResult<DataSourcesQuery, DataSourcesQueryVariables>;