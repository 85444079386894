import { IRequirement } from "~src/shared/dataSourcesRequirements/hooks/useFetchDataSourceRequirements";
import { IVendor } from "~src/shared/types";

export enum IVendorInboxState {
  CAN_TRADE = "CAN_TRADE",
  DECLINED = "DECLINED",
  IN_REVIEW = "IN_REVIEW",
  USER_ACTION_REQUIRED = "USER_ACTION_REQUIRED",
}

export const computeInboxStateForVendor = (
  vendor: IVendor,
  requirements: IRequirement[],
): IVendorInboxState => {
  // First, check if any existing data sources need to be reconnected or if any
  // additional data sources are required, regardless of whether the vendor is
  // declined (we periodically approve vendors who were previously declined).
  const relinkOrAdditionalRequired = requirements.some((req) => req.type !== "initial");
  if (relinkOrAdditionalRequired) {
    return IVendorInboxState.USER_ACTION_REQUIRED;
  }

  // No relinks required and vendor is declined.
  if (vendor.isDeclined) {
    return IVendorInboxState.DECLINED;
  }

  // Vendor is not declined, vendor has to satisfy data source
  // requirements (at this point "initial")
  if (requirements.length > 0) {
    return IVendorInboxState.USER_ACTION_REQUIRED;
  }

  // Vendor has no data source requirements to satisfy but they are not
  // approved to trade yet.
  if (!vendor.canTrade) {
    return IVendorInboxState.IN_REVIEW;
  }

  // Otherwise vendor can trade.
  return IVendorInboxState.CAN_TRADE;
};
