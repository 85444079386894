/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { ReallyOldTextDontTouchAtAll } from "~src/designSystem/deprecated/Text";
import { Flexbox } from "~src/designSystem/layout/Flexbox";

import { IPayoutAccount } from "./PayoutAccounts";

interface IPayoutMethodDetailsProps {
  payoutTo: IPayoutAccount;
}

export const PayoutMethodDetails: React.FC<IPayoutMethodDetailsProps> = (props) => (
  <>
    {props.payoutTo.plaid_item != null && (
      <Flexbox
        row
        alignItems="center"
        css={css`
          margin-right: 11px;
        `}
      >
        <img
          src={`data:image/png;base64, ${props.payoutTo.plaid_item.institution_logo}`}
          height="17"
          width="17"
          css={css`
            outline: none !important;
          `}
        />
      </Flexbox>
    )}
    <ReallyOldTextDontTouchAtAll color="#FFFFFF" weight="500" size="14px">
      ••• {props.payoutTo.mask}
    </ReallyOldTextDontTouchAtAll>
  </>
);
