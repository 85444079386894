import React from "react";

import { useStepper } from "~src/shared/stepper/stepperContext";
import { ISegmentTrackEvent, useAnalytics } from "~src/shared/thirdParties/segment";
import { LegalEntityFormHome } from "~src/vendor/settingsLegalEntities/LegalEntityFormHome";

type IOptions = {
  onSuccess?: () => void;
};

export const useLegalEntityFormNewVendor = (options: IOptions = {}): (() => void) => {
  const { addAndOpenStepperDialog, clearStepperStack } = useStepper();
  const { trackEvent } = useAnalytics();
  const { onSuccess } = options;

  return () =>
    addAndOpenStepperDialog({
      component: <LegalEntityFormHome onSuccess={onSuccess} onSubmit={clearStepperStack} />,
      config: {
        title: "Legal entity details",
        onCloseOverride: () => {
          trackEvent(ISegmentTrackEvent.VendorLegalEntityFormInboxClosed);
          clearStepperStack();
        },
      },
    });
};
