import React from "react";

import { AuthContext } from "~src/shared/auth/AuthProvider";
import { IVendor } from "~src/shared/types";

export const useMaybeVendor = (): IVendor | null => React.useContext(AuthContext).vendor;

/**
 * Gets the currently logged in vendor
 * @deprecated Use useUser whenever possible
 */
export const useVendor = (): IVendor => {
  const vendor = useMaybeVendor();
  if (vendor === null) {
    throw new Error("Must be authenticated to use useVendor");
  }
  return vendor;
};
