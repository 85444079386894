import React from "react";

import { ErrorBoundary } from "~src/shared/errors/components/ErrorBoundary";
import { ErrorComponent } from "~src/shared/errors/components/ErrorComponent";

import { InboxInner } from "../components/InboxInner";
import { PayoutAccountsProvider } from "../components/OrderBox/PayoutMethod/PayoutAccounts";
import { ProposalsProvider } from "../contexts/ProposalsContext";

export const Inbox: React.FC = () => {
  return (
    <ErrorBoundary fallback={<ErrorComponent />}>
      <ProposalsProvider>
        <PayoutAccountsProvider>
          <InboxInner />
        </PayoutAccountsProvider>
      </ProposalsProvider>
    </ErrorBoundary>
  );
};
