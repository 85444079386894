import * as Types from '../../../__generated__/graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UseLegalEntitiesVariables = Types.Exact<{
  vendorID: Types.Scalars['String'];
}>;


export type UseLegalEntities = { readonly __typename?: 'query_root', readonly vendors: ReadonlyArray<{ readonly __typename?: 'vendors', readonly public_id: string, readonly legal_entities: ReadonlyArray<{ readonly __typename?: 'legal_entities', readonly public_id: string, readonly address1: string, readonly address2: string | null, readonly city: string, readonly country: Types.country_code_enum_enum, readonly entity_type: Types.legal_entity_type_enum_enum, readonly name: string, readonly postal_code: string, readonly state: string | null, readonly tin: string | null, readonly state_of_incorporation: string | null, readonly middesk_business_status: Types.middesk_business_status_enum_enum | null }> }> };


export const UseLegalEntitiesDocument = gql`
    query UseLegalEntities($vendorID: String!) {
  vendors(where: {public_id: {_eq: $vendorID}}) {
    public_id
    legal_entities(order_by: {created_at: asc}) {
      public_id
      address1
      address2
      city
      country
      entity_type
      name
      postal_code
      state
      tin
      state_of_incorporation
      middesk_business_status
    }
  }
}
    `;

/**
 * __useUseLegalEntities__
 *
 * To run a query within a React component, call `useUseLegalEntities` and pass it any options that fit your needs.
 * When your component renders, `useUseLegalEntities` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUseLegalEntities({
 *   variables: {
 *      vendorID: // value for 'vendorID'
 *   },
 * });
 */
export function useUseLegalEntities(baseOptions: Apollo.QueryHookOptions<UseLegalEntities, UseLegalEntitiesVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UseLegalEntities, UseLegalEntitiesVariables>(UseLegalEntitiesDocument, options);
      }
export function useUseLegalEntitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UseLegalEntities, UseLegalEntitiesVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UseLegalEntities, UseLegalEntitiesVariables>(UseLegalEntitiesDocument, options);
        }
export type UseLegalEntitiesHookResult = ReturnType<typeof useUseLegalEntities>;
export type UseLegalEntitiesLazyQueryHookResult = ReturnType<typeof useUseLegalEntitiesLazyQuery>;
export type UseLegalEntitiesQueryResult = Apollo.QueryResult<UseLegalEntities, UseLegalEntitiesVariables>;